if (window.GA4) {
  const googleAnalytics = function(event) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', window.GA4, { page_location: event.detail.url });
  }
  document.addEventListener('turbo:load', googleAnalytics, false)
}


