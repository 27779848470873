import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'item',
    'autofocus'
  ]
  
  static classes = [
    'hidden'
  ]

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
  }

  toggle() {
   this.itemTarget.classList.toggle(this.class)
   if (!this.itemTarget.classList.contains(this.class)) {
    this.focusInput()
   }
  }

  show () {
    this.itemTarget.classList.remove(this.class)
    this.focusInput()
  }

  hide () {
    this.itemTarget.classList.add(this.class)
  }

  focusInput() {
    if (this.hasAutofocusTarget) {
      this.autofocusTarget.focus()
    }
  }
}