import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.uppercase = this.uppercase.bind(this)
    this.element.addEventListener('input', this.uppercase)
  }

  disconnect() {
    this.element.removeEventListener('input', this.uppercase)
  }

  uppercase(event) {
    const { value } = event.target
    this.element.value = value.toUpperCase()
  }

}