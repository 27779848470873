import { Controller } from '@hotwired/stimulus'
//import 'flowbite'
//import 'flowbite/dist/flowbite.turbo.js'


export default class extends Controller {

  static values = { 
    closable: {
      type: Boolean,
      default: true
    }
  }

  connect() {
    this.hide = this.hide.bind(this)
    document.addEventListener('CustomModalClose', this.hide)
    const options = {
      onHide: () => this.onHide(),
      closable: this.closableValue,
      //onShow: () => this.onShow(),
      backdropClasses: 'bg-slate-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 backdrop-blur-sm',
    }
    this.modal = new Modal(this.element, options)
    this.modal.show()
  }

  hide() {
    this.modal.hide()
  }

  /*
  onShow() {
  }
  */
  
  onHide() {
    const modalFrame = document.getElementById('modal_frame')
    modalFrame.removeAttribute('src')
    modalFrame.replaceChildren()
    this.element.remove()  
  }

  disconnect() {
    document.removeEventListener('CustomModalClose', this.hide)
  }

}