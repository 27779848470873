import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {

  static values = {
    path: String
  }

  static targets = [
    'loader'
  ]

  connect() {
    this.load = this.load.bind(this)
    document.addEventListener('turbo:load', this.load)
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.load)
    if (this.chart) {
      this.chart.destroy()
    }
  }

  async load() {
    if (!window.ApexCharts) return
    const response = await get(this.pathValue, {
      contentType: 'application/json'
    })
    if (response.ok) {
      const json = await response.json
      this.render(json)
    }
  }

  render(options) {
    options.theme.mode = this.mode
    options.yaxis = {
      labels: {
        formatter: (value) => {
          const num = Math.abs(value) > 999 ? Math.sign(value)*((Math.abs(value)/1000).toFixed(1)) + 'k' : Math.sign(value)*Math.abs(value)
          return ['₱', num.toString()].join('')
          /*
          const amount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'PHP',
            minimumFractionDigits: 0
          })
          return amount.format(value)
          */
        }
      }
    }
    if (this.hasLoaderTarget) {
      this.loaderTarget.remove()
    }
    this.chart = new ApexCharts(this.element, options)
    this.chart.render()
  }

  get mode() {
    const mode = localStorage.getItem('theme')
    if (mode) return mode
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark'
    } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      return 'light'
    } else {
      return 'light'
    }
  }


}