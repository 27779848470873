import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'menu'
  ]

  connect() {
    const options = {
      placement: 'right',
      backdropClasses: 'bg-slate-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      bodyScrolling: false
    }
    this.drawer = new Drawer(this.menuTarget, options)
    this.menuTarget.classList.remove('hidden')
    this.uncacheDrawer = this.uncacheDrawer.bind(this)
    document.addEventListener('turbo:before-cache', this.uncacheDrawer)
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.uncacheDrawer)
  }

  show() {
    this.drawer.show()
  }

  hide() {
    this.drawer.hide()
  }

  async uncacheDrawer(_event) {
    this.hide()
  }

}