import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {

  static values = {
    resourceName: String,
    paramName: {
      type: String,
      default: 'position'
    },
    responseKind: {
      type: String,
      default: 'html'
    },
    animation: Number,
    handle: String
  }

  initialize () {
    this.onUpdate = this.onUpdate.bind(this)
    this.sortables = []
  }

  connect () {
    const items = this.element.querySelectorAll('[data-sortable="group"]')
    if (!items.length) return;
    items.forEach(sortable => {
      this.sortables.push(
        new Sortable(sortable, {
          handle: '.handle',
          onUpdate: this.onUpdate
        })
      )
    })  
  }

  disconnect () {
    this.sortables.forEach((sortable) => {
      sortable.destroy
      sortable = undefined
    })
    this.sortables = []
  }

  async onUpdate ({ item, newIndex }) {
    if (!item.dataset.sortableUpdatePath) return
    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue
    const data = new FormData()
    data.append(param, newIndex + 1)
    return await patch(item.dataset.sortableUpdatePath, { body: data, responseKind: this.responseKindValue })
  }

  get options () {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue || this.defaultOptions.handle || undefined,
      onUpdate: this.onUpdate
    }
  }

  get defaultOptions () {
    return {}
  }
}