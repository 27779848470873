import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'iconDark',
    'iconLight'
  ]

  connect() {
    this.mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')
    this.themeEvent = this.themeEvent.bind(this)
    this.select()
  }

  disconnect() {
    this.removeListeners()
  }

  select() {
    const mode = localStorage.getItem('theme')
    this.setTheme(mode)
  }

  toggle(event) {
    const mode = event.params.mode
    this.setTheme(mode)
  }

  setTheme(mode) {
    switch (mode) {
      case 'dark':
        localStorage.setItem('theme', 'dark')
        this.removeListeners()
        this.setDarkTheme()
        break
      case 'light':
        localStorage.setItem('theme', 'light')
        this.removeListeners()
        this.setLightTheme()
        break
      default:
        localStorage.removeItem('theme')
        this.addListeners()
        this.setAutoTheme()
        break
    } 
  }

  setDarkTheme() {
    document.documentElement.classList.add('dark')
    if (this.hasIconDarkTarget && this.hasIconLightTarget) {
      this.iconDarkTargets.forEach(target => target.classList.remove('hidden'))
      this.iconLightTargets.forEach(target => target.classList.add('hidden'))
      /*
      this.iconDarkTarget.classList.remove('hidden')
      this.iconLightTarget.classList.add('hidden')
      */
    }
  }

  setLightTheme() {
    document.documentElement.classList.remove('dark')
    if (this.hasIconDarkTarget && this.hasIconLightTarget) {
      this.iconDarkTargets.forEach(target => target.classList.add('hidden'))
      this.iconLightTargets.forEach(target => target.classList.remove('hidden'))
      /*
      this.iconDarkTarget.classList.add('hidden')
      this.iconLightTarget.classList.remove('hidden')
      */
    }
  }

  setAutoTheme() {
    if (this.mediaQueryList.matches) {
      this.setDarkTheme()
    } else {
      this.setLightTheme()
    }
  }

  addListeners() {
    this.mediaQueryList.addEventListener('change', this.themeEvent)
  }

  removeListeners() {
    this.mediaQueryList.removeEventListener('change', this.themeEvent)
  }

  themeEvent(event) {
    if (event.matches) {
      this.setDarkTheme()
    } else {
      this.setLightTheme()
    }
  }

}
