// Entry point for the build script in your package.json

import "flowbite/dist/flowbite.turbo"
import "@hotwired/turbo-rails"
import "./controllers"
import "./support"
import "./../assets/javascripts/serviceworker-companion"

//Turbo.session.drive = false

