import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'variantTemplate',
    'miscellaneousTemplate',
  ]

  connect() {
    this.addVariant = this.addVariant.bind(this)
    this.addMiscellaneous = this.addMiscellaneous.bind(this)
    document.addEventListener('combobox-add-existing', this.addVariant)
    document.addEventListener('combobox-add-new', this.addMiscellaneous)
  }

  addVariant(event) {
    const { item: variant } = event.detail
    let content = this.variantTemplateTarget.innerHTML
    content = content.replace(/{index}/g, new Date().valueOf())
    content = content.replace(/{variant_id}/g, variant.id)
    content = content.replace(/{code}/g, variant.code)
    content = content.replace(/{name}/g, variant.name)
    content = content.replace(/{amount}/g, variant.amount)
    //this.element.insertAdjacentHTML('beforeend', content)
    this.variantTemplateTarget.insertAdjacentHTML('beforebegin', content)
  }

  addMiscellaneous() {
    let content = this.miscellaneousTemplateTarget.innerHTML
    content = content.replace(/{index}/g, new Date().valueOf())
    //this.element.insertAdjacentHTML('beforeend', content)
    this.miscellaneousTemplateTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeVariant(event) {
    const { variant } = event.params
    if (variant) {
      this.element.dispatchEvent(
        new CustomEvent('combobox-remove', {
          bubbles: true,
          detail: { key: variant }
        })
      )
    }
  }

  disconnect() {
    document.removeEventListener('combobox-add-existing', this.addVariant)
    document.removeEventListener('combobox-add-new', this.addMiscellaneous)
  }

}