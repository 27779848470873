import { Controller } from '@hotwired/stimulus'
import Datepicker from 'flowbite-datepicker/Datepicker';

export default class extends Controller {

  connect() {
    const options = {
      format: 'yyyy-mm-dd',
      autohide: true,
      todayBtn: true,
      clearBtn: true,
      todayBtnMode: 1,
    }
    this.datepicker = new Datepicker(
      this.element,
      options
    )
  }

  disconnect() {
    if (this.datepicker) this.datepicker = null
  }
  
}