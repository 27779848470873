import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'dateSelector',
    'calendar'
  ]

  connect() {
    this.dateSelectorTarget.disabled = false
    this.dateSelectorTarget.hidden = false
    this.calendarTarget.disabled = true
    this.calendarTarget.hidden = true
  }

  show() {
    this.dateSelectorTarget.disabled = true
    this.dateSelectorTarget.hidden = true
    this.calendarTarget.disabled = false
    this.calendarTarget.hidden = false
  }

}