import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'quantity',
    'destroy',
    'id'
  ]

  decrement() {
    let quantity = parseInt(this.quantityTarget.value)
    if (isNaN(quantity)) {
      quantity = 1
    } else {
      quantity = Math.max(quantity - 1, 1)
    }
    this.quantityTarget.value = quantity
  }

  increment() {
    let quantity = parseInt(this.quantityTarget.value)
    if (isNaN(quantity)) {
      quantity = 1
    } else {
      quantity++
    }
    this.quantityTarget.value = quantity
  }

  destroy() {
    if (this.idTarget.value) {
      this.element.classList.add('hidden')
      this.element.hidden = true
      this.destroyTarget.value = true
    } else {
      this.element.remove()
    }
  }

}