import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'menu',
    'trigger'
  ]

  connect() {
    const options = {
      triggerType: 'click',
      placement: 'bottom-end'
    }
    if (this.hasMenuTarget && this.hasTriggerTarget) {
      this.dropdown = new Dropdown(
        this.menuTarget,
        this.triggerTarget, 
        options
      )
    }
  }

  disconnect() {
    if (this.dropdown) this.dropdown = null
  }
  
}