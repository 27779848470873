import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = {
    exclude: Array
  }

  static targets = [
    'input',
    'list',
    'filterable',
    'filterableNone'
  ]

  connect() {
    this.selectedItems = []
    this.excludedItems = this.excludeValue
    this.refresh()
    this.close()
    this.mouseDown = false
    this.onInputFocus = this.onInputFocus.bind(this)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onListMouseDown = this.onListMouseDown.bind(this)
    this.inputTarget.addEventListener('focus', this.onInputFocus)
    this.inputTarget.addEventListener('blur', this.onInputBlur)
    this.listTarget.addEventListener('mousedown', this.onListMouseDown)
    this.remove = this.remove.bind(this)
    document.addEventListener('combobox-remove', this.remove)
  }

  onInputFocus() {
    this.open()
  }

  onInputBlur() {
    if (this.mouseDown) return
    this.close()
  }

  onListMouseDown() {
    this.mouseDown = true
    this.listTarget.addEventListener('mouseup', () => {
      this.mouseDown = false
    }, { once: true })
  }

  select(event) {
    const { selected } = event.params
    this.element.dispatchEvent(
      new CustomEvent('combobox-add-existing', {
        bubbles: true,
        detail: { item: selected }
      })
    )
    this.excludedItems.push(selected.id)
    this.selected = selected
    this.refresh()
    this.close()
  }

  add() {
    this.element.dispatchEvent(
      new CustomEvent('combobox-add-new', {
        bubbles: true,
        detail: {}
      })
    )
  }

  refresh() {
    this.selectable = []
    this.filterableTargets.forEach((element) => {
      if (this.excludedItems.includes(element.dataset.key)) {
        element.hidden = true
      } else {
        element.hidden = false
        this.selectable.push(element)
      }
    })
    if (this.selectable.length == 0 && this.hasFilterableNoneTarget) {
      this.filterableNoneTarget.hidden = false
      this.filterableNoneTarget.textContent = 'No selectable products'
    } else {
      this.filterableNoneTarget.hidden = true
    }
  }

  filter() {
    if (this.selectable.length == 0) return
    let found = 0
    this.selectable.forEach((element) => {
      const content = element.getAttribute('data-content')
      if (content.includes(this.terms)) {
        element.hidden = false
        found++
      } else {
        element.hidden = true
      }
    })
    if (this.hasFilterableNoneTarget) {
      this.filterableNoneTarget.hidden = found > 0
      this.filterableNoneTarget.textContent = `No products found matching "${this.terms}"`
    } else {
      this.listTarget.hidden = found == 0
    }
  }

  open() {
    if (this.hasListTarget) {
      this.listTarget.hidden = false
    }
  }

  close() {
    if (this.hasListTarget) {
      this.listTarget.hidden = true
    }
  }

  remove(event) {
    const { key: variantId } = event.detail
    const index = this.excludedItems.indexOf(variantId)
    if (index >= 0) {
      this.excludedItems.splice(index, 1)
      this.refresh()
    }
  }

  get selected() {
    return this.selectedItems
  }

  set selected(item) {
    this.selectedItems.push(item)
  }

  get terms() {
    return this.inputTarget.value.toLowerCase()
  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener('focus', this.onInputFocus)
      this.inputTarget.removeEventListener('blur', this.onInputBlur)
    } 
    if (this.hasListTarget) {
      this.listTarget.removeEventListener('mousedown', this.onMouseDown)
    }
    document.removeEventListener('combobox-remove', this.remove)
  }

}