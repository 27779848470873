import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.offset = 100
    this.lastKnownScrollY = 0
    this.ticking = false
    this.attachEvents()
  }

  disconnect() {
    this.detachEvents()
  }

  attachEvents() {
    this.boundScroll = this.scroll.bind(this)
    window.addEventListener('scroll', this.boundScroll)
  }

  detachEvents() {
    document.removeEventListener('scroll', this.boundScroll)
  }

  scroll() {
    this.currentScrollY = Math.max(0, window.pageYOffset)
    this.requestTick()
  }

  requestTick() {
    if (!this.ticking) {
      requestAnimationFrame(e => { this.update() })
    }
    this.ticking = true
  }

  update() {
    if (this.currentScrollY < this.lastKnownScrollY) {
      this.pin()
    } else if (this.currentScrollY > this.lastKnownScrollY && this.currentScrollY > this.offset) {
      this.unpin()
    }
    this.lastKnownScrollY = this.currentScrollY
    this.ticking = false
  }

  pin() {
    if (!this.element.classList.contains('sticky-unpin')) return
    this.element.classList.remove('sticky-unpin')
  }

  unpin() {
    if (this.element.classList.contains('sticky-unpin')) return
    this.element.classList.add('sticky-unpin')
  }

}