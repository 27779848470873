import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'source',
    'on',
    'off'
  ]

  connect() {
    this.render(this.sourceTarget.checked)
  }

  change(event) {
    this.render(event.target.checked)
  }

  render(checked) {
    if (checked) {
      this.onTarget.classList.remove('hidden')
      this.offTarget.classList.add('hidden')
    } else {
      this.onTarget.classList.add('hidden')
      this.offTarget.classList.remove('hidden')
    }
  }

}
